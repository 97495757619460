import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import YoutubeIcon from '../../../../components/icons/YoutubeIcon';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import Link from '../../../../components/Link';
import Seo from '../../../../components/Seo';

const IstorychniPisniPage = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
    query {
      songs: allSong(filter: {category: {eq: "istorychni-pisni"}}, sort: {fields: title}) {
        nodes {
          title
          slug
          hasYoutube
        }
      }
    }
  `);

  const songs = data.songs.nodes;

  return (
    <>
      <Seo
        title="Історичні пісні"
        description="Історична пісня — це ліро-епічний твір, у якому відтворено історичні події чи конкретні історичні постаті та висловлено ставлення народу до них."
      />
      <div className="bg-red-500 text-white">
        <div className="container-lg py-10 md:py-24">
          <Breadcrumbs
            crumbs={pageContext.breadcrumb.crumbs}
          />
          <h1 className="typo-h1 lowercase mt-4">
            Історичні пісні
          </h1>
          <p className="typo-body mt-4 max-w-xl">
            Історична пісня — це ліро-епічний твір, у якому відтворено історичні події чи конкретні історичні постаті та висловлено ставлення народу до них.
          </p>
        </div>
      </div>
      <div className="container-lg mt-10 md:mt-24">
        <h2 className="typo-h2">
          Список історичних пісень
        </h2>
        <ul className="row mt-8">
          {songs.map((song) => (
            <li className="col-full md:col-4 mb-4">
              <Link to={song.slug} className="typo-small flex items-center hover:text-red-500">
                {song.title}
                {song.hasYoutube && (
                  <YoutubeIcon className="ml-2 w-4 h-4" />
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="container-lg py-10 md:py-24">
        <h2 className="typo-h2">
          Опис жанру історичної пісні
        </h2>
        <p className="typo-body mt-4">
          Основними персонажами пісень цього жанру являються історичні особистості, великі справи яких, назавжди
          залишилися в думках людей. Завдяки збереженим даним із літописів, ми можемо мати дані про визначні епізоди в
          історії. Крім цього, багато даних про події були збережені завдяки книгам, хоч вони і мають більш точні дані,
          однак вони лишаються сухими. Перетворюючи історії в пісенну форму, вони наче оживають, стають наповненими
          фарбами та звуками.
        </p>
        <p className="typo-body mt-4">
          Найбільш давні пісні, які надійшли до нас, датуються ХV сторіччям. Це були часи турецько-татарського
          лихоліття, руїн та пожеж: “стару неньку зарубали, а миленьку в полон взяли…” (“За річкою вогні горять”).
          Проте й за тих часів, і пізніше розорені, розтоптані ординською кіннотою люди не принижувались до того, щоб з
          хлібом-сіллю просить змилування у ворогів:
        </p>
        <p className="typo-body italic mt-4">
          — Бодай турки і татари того не діждали,
          <br />
          Щоб наші побережці з хлібом виходжали!
          <br />
          (Славна була в Побережу всіма сторонами)
        </p>
        <p className="typo-body mt-4">
          Навіть жінки брали в руки зброю, захищаючи рідний край. Пісня “Славний город Ведмедівка” повідає, як
          ведмедівська попівна “сімсот турків-яничар з коней повалила”. Зрозуміло, що справа тут не в названій цифрі, а
          в поетичному відображенні кривавої боротьби ведмедівців з напасниками.
        </p>
        <p className="typo-body mt-4">
          Таке ж символічне число назване у пісні про вірного соратника Богдана Хмельницького — Івана Богуна:
        </p>
        <p className="typo-body italic mt-4">
          Ой наточив Іван Богун невірам вина —
          <br />
          Та було їх сорок тисяч, а тепер нема!
        </p>
        <p className="typo-body mt-4">
          Про одчайдушного Данила Нечая, завзятого Максима Кривоноса — героїв визвольної війни 1648 — 1654 рр., можливо,
          склали пісні їхні сподвижники, настільки живо і яскраво вимальовуються їхні постаті. Оспівані у піснях і герої
          повстання 1768 р., названого Коліївщиною — Залізняк, Гонта, Гнат Голий. Протягом трьох віків карається
          народним презирством зрадник Сава Чалий (“Ой був в Січі старий козак”).
        </p>
        <p className="typo-body mt-4">
          …Ось вже на горі дозріли жита, женці збирають колоски, зрізуючи їх серпами, а підвівши свій погляд та
          випрямивши спини, помічають, що ввесь виярок цвіте червоними маками — а то запорізьке військо; женці бачать,
          попереду йде Дорошенко, який веде всіх за собою; проводжають добрими поглядами Сагайдачного, який не поспішає,
          а розкурює люльку “Ой на горі да женці жнуть”).
        </p>
        <p className="typo-body mt-4">
          Як з найближчим другом ділиться з вами своїм горем Устим Кармелюк, що, наголодувавшись, намерзнувшись у
          сибірських казематах, витерпівши нелюдські муки, позбувся кайданів, але й у себе вдома потрапив у неволю.
          Тільки рідній людині можна виповісти те, що гнітить серце: “Маю жінку, маю діти, та я їх не бачу, як згадаю
          про їх долю, сам не раз заплачу”. Як наївно-довірливо звучать його слова про намагання встановити справедливу
          рівність для всіх: “Я багатих розбиваю, бідних награждаю, а так гроші розділивши, сам гріха не маю”
          (“Повернувся я з Сибіру”).
        </p>
        <p className="typo-body mt-4">
          У творі Миколи Гоголя описані особливості народної історичної пісні, а саме про те, що для історика, не має
          бути в пріорітеті пошуки конкретних днів і чисел битв або точного вказування місця події… Однак головним
          являється пізнання реального побутового диття, всі барви почуттів, радісних чи навпаки сумних. Коли людина
          захоче відчути дух минулих сторіч, особливості всього цільного, а також індивідуального, тоді вона буде
          наповнена і задоволена повністю: саме в цей момент перед нею відкрилася історія народ в усій красі.
        </p>
        <p className="typo-body mt-4">
          Пісні історичного характеру надихали багатьох поетів, драматургів, прозаїків, наприклад ряд віршів Т. Шевченка
          побудовано на сюжетах і образах історичних пісень. У повісті Марка Вовчка “Кармелюк” знаходимо цитати з
          історичних пісень про У. Кармелюка. В кінокартині “Богдан Хмельницький” (за п’єсою О. Корнійчука) звучать
          фрагменти народних історичних пісень про самого Богдана та його бойових побратимів — Кривоноса, Нечая, Богуна
          та ін.
        </p>
      </div>
    </>
  );
};

export default IstorychniPisniPage;
